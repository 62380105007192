.container {
  height: 411px;
  width: 100%;
}

.inner-container {
  width: 100%;
  height: 100%;
  border-radius: 1em !important;
  overflow: hidden;
}
