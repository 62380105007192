.container {
  display: flex;
  justify-content: space-between;
  border-radius: 1em !important;
  overflow: hidden;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  /* flex: 1; */
}

.text-margin {
  margin-left: -1em;
}

.title {
  color: var(--text-color1);
  text-align: center;
}

.data-text {
  text-align: center;
  font-weight: bold !important;
}
