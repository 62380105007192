body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

:root {
  --blue800: #3361ff;
  --blue50: #f0f3ff;

  --purple800: #8833ff;
  --purple50: #ddc7fc;

  --twitter-blue500: #33bfff;
  --twitter-blue400: #6ad2ff;

  --green800: #128822;
  --green500: #29cc39;
  --green50: #f4fcf5;

  --orange800: #ff6633;
  --orange50: #fff7f5;

  --yellow500: #feca33;

  --background-color: #f7f8fa;

  --text-color1: #bec7d6;

  --white: #fff;
  --black: #000;
}
