.container {
  display: flex;
  justify-content: center;
  /* gap: 1em; */
  border-radius: 1em !important;
  overflow: hidden;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  flex: 1;
}

.title {
  color: var(--text-color1);
  text-align: center;
}

.data-text {
  font-weight: bold !important;
}
